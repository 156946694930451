<template>
  <div class="merch">
    <!-- 搜索列 -->
    <el-form
      ref="queryParams"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item>
        <el-select
          clearable
          v-model="queryParams.auditSataus"
          placeholder="状态"
        >
          <el-option label="通过" :value="1"></el-option>
          <el-option label="拒绝" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.articleNumber"
          placeholder="商品货号/名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery" style="width: 80px"
          >查 询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      v-loading="loading"
      border
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column prop="id" label="编号" align="center" />
      <el-table-column label="商品信息" align="center" width="350">
        <template slot-scope="{ row }">
          <div class="fx">
            <div v-if="row.pictureUrl">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                :src="handleImageError(row.pictureUrl[0])"
                :preview-src-list="row.pictureUrl"
              >
              </el-image>
            </div>
            <div
              style="
                text-align: left;
                font-size: 12px;
                color: #777;
                line-height: 20px;
              "
            >
              <div style="font-size: 14px; color: #000">
                {{ row.goodsName }}
              </div>
              <div>规格：{{ row.specification }}</div>
              <div>厂家：{{ row.manufacturer }}</div>
              <div>产地：{{ row.producingArea }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="approvalNumber" label="批准文号" align="center" />
      <el-table-column prop="dosageForm" label="剂型" align="center" />
      <el-table-column
        prop="pictureUrl"
        label="更改后"
        align="center"
        v-if="fileType == 2"
      >
        <template slot-scope="{ row }">
          <div v-if="row.pictureUrl">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              :src="handleImageError(row.pictureUrl[0])"
              :preview-src-list="row.pictureUrl"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="goodsSpecification"
        label="说明书"
        align="center"
        v-if="fileType == 3"
        width="300"
      >
        <template slot-scope="{ row }">
          <div v-if="row.goodsSpecification">
            <div class="fx">
              <span
                class="single-line-ellipsis"
                v-html="row.goodsSpecification"
              ></span>
              <span>...</span>
            </div>
            <span class="btsed" @click="ViewInstructionManual(row)"
              >立即查看</span
            >
          </div>
          <div v-else>——</div>
        </template>
      </el-table-column>
      <el-table-column label="资料库信息" align="center" width="350">
        <template slot-scope="{ row }">
          <div class="fx">
            <div v-if="row.platformgoodspictureurl">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                :src="handleImageError(row.platformgoodspictureurl[0])"
                :preview-src-list="row.platformgoodspictureurl"
              >
              </el-image>
            </div>
            <div
              style="
                text-align: left;
                font-size: 12px;
                color: #777;
                line-height: 20px;
              "
            >
              <div style="font-size: 14px; color: #000">
                {{ row.platformGoodsInfoName }}
              </div>
              <div>规格：{{ row.platformGoodsInfoSpecification }}</div>
              <div>厂家：{{ row.platformGoodsInfoManufacturer }}</div>
              <div>批准文号：{{ row.platformGoodsInfoApprovalNumber }}</div>
              <div>产地：{{ row.platformGoodsInfoPlaceOrigin }}</div>
              <div>资料库ID：{{ row.platformGoodsInfoId }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="审核结果" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.auditStatus"
            active-color="#13ce66"
            inactive-color="#cccccc"
            :active-value="1"
            :inactive-value="2"
            :disabled="detailItem.submitStatus == 1"
            @change="changeStatus(row.id, row.auditStatus)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="refuseReason" label="拒绝原因" align="center" />
      <el-table-column
        :label="fileType == 2 ? '是否替换资料库图片' : '是否替换资料库说明书'"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.isUpdate"
            active-color="#13ce66"
            inactive-color="#cccccc"
            :active-value="1"
            :inactive-value="0"
            :disabled="detailItem.submitStatus == 1"
            @change="changeUpdate(row.id, row.isUpdate)"
          />
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
    <div class="boombtn">
      <div>
        共{{ detailItem.totalNum }}个品种，审核通过{{ detailItem.passNum }}个
      </div>
      <el-button
        v-if="detailItem.submitStatus == 0"
        type="primary"
        :disabled="btnloading"
        @click="submit"
      >
        {{ btnloading ? "对码中" : "提 交" }}
      </el-button>
    </div>
    <!-- 拒绝弹框 -->
    <el-dialog
      title="拒绝原因"
      :visible.sync="isRefuse"
      :before-close="dialogpay"
      :close-on-click-modal="false"
      width="500px"
    >
      <div>
        <el-input
          type="textarea"
          :rows="7"
          placeholder="请输入内容"
          v-model="AuditMessage.remark"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogpay">取 消</el-button>
        <el-button type="primary" @click="notarizeRefuse">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 说明书弹窗 -->
    <el-dialog
      title="说明书"
      :visible.sync="dialogInstruction"
      :close-on-click-modal="false"
      width="800px"
    >
      <div style="height: 550px; width: 750px; margin-top: -10px">
        <pre><Editor v-model="goodsSpecification" class="Editor" /></pre>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogInstruction = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGoodsImportAuditList, //审核明细列表
  goodsImportAuditToggle, //审核状态切换
  goodsImportAuditReplaceGoodsImage, //替换状态切换（图片或说明书）
  goodsImportAuditSubmit, //商品审核编辑-审核提交
  getGoodsImportAuditGoods, //商品文件审核详情
} from "@/api/apiAll/phpUrl.js";
import UpimgList from "@/components/Updata/UpimgList.vue";
import Editor from "@/components/Editor/index.vue"; //商品说明书
export default {
  name: "",
  components: {
    UpimgList,
    Editor,
  },
  data() {
    return {
      detailItem: {
        lastUpdateTime: "", //数据最后更新时间
        totalNum: "", //总数量
        passNum: "", //审核通过数量
        submitStatus: "", //提交状态0未提交1已提交
      },
      btnloading: false,
      id: this.$route.query.id,
      fileType: "",
      loading: false,
      queryParams: {
        id: "",
        page: 1,
        perPage: 10,
        auditSataus: "", //审核状态0：拒绝 1：通过
        articleNumber: "",
      },
      total: 0,
      dataList: [],

      isRefuse: false, //拒绝弹框
      AuditMessage: {
        id: "",
        remark: "", //拒绝原因
      },

      dialogInstruction: false,
      goodsSpecification: "",
    };
  },
  created() {
    this.fileType = this.$route.query.fileType;
    this.getList();
    this.getdetail();
  },
  methods: {
    async getdetail() {
      let res = await getGoodsImportAuditGoods({ id: this.$route.query.id });
      if (res.code == 200) {
        this.detailItem = res.data;
      }
    },
    async getList() {
      this.loading = true;
      this.queryParams.id = this.$route.query.id;
      let res = await getGoodsImportAuditList(this.queryParams);
      this.loading = false;
      if (res.code == 200) {
        this.dataList = res.data.data;
        this.total = res.data.total;
      }
    },
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 审核状态切换判断*/
    async changeStatus(id, status) {
      if (status == 2) {
        this.AuditMessage.remark = "";
        this.AuditMessage.id = id;
        this.isRefuse = true;
      } else {
        this.chagetog(id, status);
      }
    },

    /** 关闭拒绝原因弹窗 */
    dialogpay() {
      this.isRefuse = false;
      this.getList();
    },
    /** 关闭拒绝原因 */
    notarizeRefuse() {
      if (this.AuditMessage.remark == "")
        return this.$message.error("请输入拒绝原因");
      this.chagetog(this.AuditMessage.id, 2);
    },
    /** 审核状态切换 */
    async chagetog(id, status) {
      let res = await goodsImportAuditToggle({
        id: id,
        // status: status,
        refuse_remark: this.AuditMessage.remark,
      });
      this.getList();
      this.getdetail();
      this.isRefuse = false;
      if (res.code == 200) {
        this.$message.success("操作成功");
      }
    },

    /** 是否替换资料库图片*/
    async changeUpdate(id, status) {
      let res = await goodsImportAuditReplaceGoodsImage({
        id: id,
        //   isUpdate: status,
      });
      this.getList();
      this.getdetail();
      if (res.code == 200) {
        this.$message.success("操作成功");
      }
    },
    /** 提交 */
    async submit() {
      this.btnloading = true;
      let res = await goodsImportAuditSubmit({
        id: this.$route.query.id,
      });
      this.btnloading = false;
      if (res.code == 200) {
        this.$message.success(res.message);
        this.getList();
        this.getdetail();
        this.$router.push({ path: "/auditGoodsFile" });
      }
    },
    ViewInstructionManual(row) {
      this.goodsSpecification = row.goodsSpecification;
      this.dialogInstruction = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.boombtn {
  background: #eee;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-line-ellipsis {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
